<template>
   <section class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>数据中台</el-breadcrumb-item>
            <el-breadcrumb-item>会员标签</el-breadcrumb-item>
            <el-breadcrumb-item>标签群组</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>群组名称：</label>
                  <el-input v-model="param.groupName"></el-input>
               </el-row>
               <el-row class="search-item">
                  <label>群组类型：</label>
                  <el-select v-model="param.groupUnder" placeholder="请选择">
                     <el-option v-for="(label, value) in dictData['member-group-type']"
                                :key="value"
                                :label="label"
                                :value="value">
                     </el-option>
                  </el-select>
               </el-row>
               <el-row class="search-item" v-if="accountType !== 'PLATFORM'">
                  <label>创建类型：</label>
                  <el-select v-model="param.groupType" placeholder="请选择">
                     <el-option v-for="(label, value) in dictData['group-create-type']"
                                :key="value"
                                :label="label"
                                :value="value">
                     </el-option>
                  </el-select>
               </el-row>
               <el-button class="bg-gradient" type="primary" icon="el-icon-search" @click="handleQuery(true)">搜索</el-button>
               <el-button  icon="el-icon-refresh-right" plain @click="handleQuery(false)">重置</el-button>
            </el-row>
         </el-row>
         <!-- 主体内容 -->
         <el-row class="table-box">
            <div class="table-head-btn">
               <el-row></el-row>
               <el-row class="right-btn">
                  <el-button class="bg-gradient" type="primary" @click="handleAddEdit('add')">新增群组</el-button>
               </el-row>
            </div>
            <el-table
                  v-loading="loading"
                  :data="tableData"
                  style="width: 100%"
                  :stripe="true"
                  border fit>
               <el-table-column label="序号" width="60">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column prop="groupName" label="群组名称" min-width="100"></el-table-column>
               <el-table-column prop="memberCount" label="用户数" min-width="70"></el-table-column>
               <el-table-column label="群组类型" min-width="70">
                  <template slot-scope="scope">
                     {{scope.row.groupUnder | filterGroupType(that)}}
                  </template>
               </el-table-column>
               <el-table-column label="创建类型" min-width="70">
                  <template slot-scope="scope">
                     {{scope.row.groupType | filterCreateType(that)}}
                  </template>
               </el-table-column>
               <el-table-column label="适用酒店" v-if="accountType === 'PLATFORM'">
                  <template slot-scope="scope">
                     <p v-if="scope.row.applicableType === '1'">全部</p>
                     <el-button v-else @click="lookHotels(scope.row.id)">查看酒店</el-button>
                  </template>
               </el-table-column>
               <el-table-column prop="updateTime" label="最近更新时间"></el-table-column>
               <el-table-column label="操作">
                  <template slot-scope="scope">
                     <el-button type="text" @click="handleAddEdit('look', scope.row)" >
                        <el-link type="primary">查看</el-link>
                     </el-button>
                     <el-button type="text" v-if="!(accountType !== 'PLATFORM' && scope.row.applicableType !== undefined)" @click="handleAddEdit('edit',scope.row)" >
                        <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                     </el-button>
                     <el-button type="text" v-if="!(accountType !== 'PLATFORM' && scope.row.applicableType !== undefined)" style="color: #F56C6C;" @click.stop="handleDel(scope.row.id)">
                        <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :currentPage="param.page" :page-size="param.limit" @handleSizeChangeSub="changePagNum" @handleCurrentChangeSub="changeCurrPage"/>
         </el-row>
      </el-row>
      <!-- 查看酒店 -->
      <el-dialog
          title="查看酒店"
          :visible.sync="visibleHotel"
          width="600px">
         <el-table
             :data="hotelList" style="height: 500px; overflow-y: auto">
            <el-table-column width="100" label="序号">
               <template slot-scope="scope">
                  {{scope.$index + 1}}
               </template>
            </el-table-column>
            <el-table-column prop="hotelName" label="酒店名称"></el-table-column>
         </el-table>
      </el-dialog>
   </section>
</template>

<script>
import { mapState } from 'vuex'
import { urlObj } from '@/api/interface'
import { label} from '@/api/interface/data'
import { getDict } from "@/common/js/common";
export default {
   data(){
      return{
         tableData: [],      // 表格数据
         loading: true,      // 加载动画
         total: 0,           // 总数目数
         that: this,
         param: {
            groupName: '',
            groupType: '',
            groupUnder: '',
            limit: 1,
            page: 1
         },
         accountType: '',
         hotelList: [],
         visibleHotel: false,
         confirm_remove: '此操作将永久删除, 是否继续?',
         prompt: '提示',
         del_success: '删除成功！',
      }
   },
   computed: {
      ...mapState(['hotelInfo', 'dictData'])
   },
   mounted() {
      this.accountType = JSON.parse(sessionStorage.getItem('userInfo') || '{}').accountType
      this.param.limit = sessionStorage.getItem('pageSize') * 1
      getDict(['member-group-type', 'group-create-type'])
      this.getTagGroup()
   },
   methods: {
      // 获取群组列表
      getTagGroup(){
         const url = label.tagGroup
         const param = { companyId: this.hotelInfo.storeId, ...this.param }
         this.$axios.post(url, param).then(res => {
            if (res.success){
               this.loading = false
               this.total = res.total
               this.tableData = res.records
            }
         })
      },
      // 改变每页数
      changePagNum(num){
         this.param.limit = num
         this.getTagGroup()
      },
      // 改变当前页
      changeCurrPage(num){
         this.param.page = num
         this.getTagGroup()
      },
      // 查询群组
      handleQuery(bool){
         this.param.page = 1
         if (bool) return this.getTagGroup()
         for (const k in this.param) k !== 'limit' && (this.param[k] = '')
      },
      // 添加会员群组
      handleAddEdit(action, row) {
         if (action === 'edit' || action === 'look') sessionStorage.setItem('groupInfo', JSON.stringify(row))
         this.$router.push({path: '/add_group', query: { action }})
      },
      // 删除群组
      handleDel(id){
         this.$confirm(this.confirm_remove, this.prompt, {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            const url = label.delTagGroup + `/${id}`
            this.$axios.get(url, {}).then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: this.del_success,
                     type: 'success'
                  })
                  this.getTagGroup()
               }
            })
         })
      },
      // 查看适用酒店
      lookHotels(groupId) {
         const url = label.getHotelListByGroupId
         const param = { groupId }
         this.$axios.post(url, param, 'json').then(res => {
            if (!res.success) return
            this.visibleHotel = true
            this.total = res.total
            this.hotelList = res.records
         })
      }
   },
   watch: {
      hotelInfo(newVal, oldVal) {
         oldVal && newVal.id !== oldVal.id && this.getTagGroup()
      }
   },
   filters: {
      filterGroupType(val, that) {
         if (val && that.dictData['member-group-type']) return that.dictData['member-group-type'][val] || val
      },
      filterCreateType(val, that) {
         if (val && that.dictData['group-create-type']) return that.dictData['group-create-type'][val] || val
      }
   }
}
</script>

<style scoped>
@media screen and (max-width: 1366px) {
  .cont {
    width: 1500px;
  }
}
</style>
